@use "@angular/material" as mat;

@mixin theme($theme) {
  .sidenav-scroll-wrapper {
    // background-color: rgba(mat.get-theme-color($theme, primary-container), 0.75);
  }
  .sidenav-content {
    @media (pointer: fine) {
      &::-webkit-scrollbar-thumb {
        background-color: mat.get-theme-color($theme, primary);
        height: 4px;              /* height of horizontal scrollbar ← You're missing this */
        width: 4px;
      }
    }
  }
}

.campaignIcon {
  margin-left: 18px !important;
  margin-right: 14px !important;
}