// Custom colours
$dark-blue: #003366;
$dark-blue-hover: #023e7a;
$mid-blue: #003366;
$light-blue: #E5F0FF;

$dark-grey: #4d4d4d79;
$mid-grey: #808097;
$light-grey: #F9F9FF;
$light-grey-2: #F6F6F6;

$error-red: #de3730;
$error-warning: #d47910;

// used in pills
$active-blue: #CCE1FF;

$completed-green: #188038;
$completed-green-2: #e6f4ea;

$pill-background-grey: #F5F5F5;
$pill-foreground-grey: #808080;
$pill-background-yellow: #FFF5CC;
$pill-background-blue: #E9F2FF;
$pill-background-green: #D9F2DD;
$pill-background-red: #FFF4F6;
$pill-foreground-red: #F55757;

$vibrant-yellow: #E3AD3F;
$vibrant-blue: #4EA7F8;

$standard-text-colour: #001f24;