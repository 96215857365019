@use "@angular/material" as mat;
@use "../m3-theme";
@use "./app/layout/layout-component.theme";
@use "./app/shared/colours.scss" as *;

@include mat.core();

$buzzwave-admin-light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

$buzzwave-admin-dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$magenta-palette,
      tertiary: mat.$violet-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

// Light theme
// Un comment below to use theme created by ng add @angular-material
$theme: $buzzwave-admin-light-theme;

// Light Theme created by ng generate @angular-material:m3-theme
$theme: m3-theme.$light-theme;

// Dark theme
// Un-comment below to use dark theme using @angular/material
// $dark-theme: $buzzwave-admin-dark-theme;

// Dark Theme created by ng generate @angular-material:m3-theme
$dark-theme: m3-theme.$dark-theme;

:root {
  @include mat.all-component-themes($theme);

  // Components themes
  @include layout-component.theme($theme);

  // Add backwards compatibility styles for color variants
  @include mat.color-variants-backwards-compatibility($theme);

  // The prefers-color-scheme CSS media feature is used to detect if a user has requested light or dark color themes. A user indicates their preference through an operating system setting (e.g. light or dark mode) or a user agent setting.
  // @media (prefers-color-scheme: dark) {
  //   @include mat.all-component-colors($dark-theme);

  //   // Components themes
  //   @include layout-component.theme($dark-theme);

  //   @include mat.color-variants-backwards-compatibility($dark-theme);
  // }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3 {
  font-weight: 500;
}

.background-vector {
  background: url("./assets/background.png");
}

// Custom classes

.color-grey {
  color: $dark-grey !important;
}/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark-blue;
  border-radius: 5pt;
  z-index: 1001;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $dark-blue;
}

.flex {
  display: flex;
  flex-flow: row wrap;
}

.flex-column {
  display: flex;
  flex-flow: column wrap;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-66 {
  max-width: 1600px;
  margin-right: 4rem;
  flex-grow: 1;
}

.flex-30 {
  // width: 40%;
  flex: 0 0 400px;
  width: 400px;
}

.fill-remaining-space {
  /* This fills the remaining space, by using flexbox. 
      Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.space-between {
  justify-content: space-between;
}

.padding-0-5 {
  padding: 0.5rem;
}

.full-width {
  width: 100%;
}

.width-33 {
  width: 33%;
}

.width-66 {
  width: 66%;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.flex-width-33 {
  flex: 0.33333 !important;
}

.flex-width-66 {
  flex: 0.66666 !important;
}

.half-width {
  width: 50%;
}

.margin-right-0-5 {
  margin-right: 0.5rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-left-3 {
  margin-left: 3rem;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-5 {
  margin-top: 0.5rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.margin-right-3 {
  margin-right: 3rem;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-1 {
  padding-top: 1rem;
}

.padding-top-2 {
  padding-top: 2rem;
}

.padding-left-1 {
  padding-left: 1rem;
}

.padding-left-2 {
  padding-left: 2rem;
}

.padding-right-1 {
  padding-right: 1rem;
}

.font-size-small {
  font-size: 12px;
}

.border-radius-top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.border-radius-bottom {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.italics {
  font-style: italic;
}

.text-small {
  font-size: 12px;
}

.preline {
  white-space: pre-line;
}

.table-heading-subtext {
  font-size: 12px;
}

.hyperlink-blue,
.hyperlink-blue:visited {
  color: blue;
}

.text-red {
  color: $error-red !important;
}

.container {
  display: flex;
  flex-direction: row;
  /* Arrange items horizontally by default */
  flex-wrap: wrap;
  /* Allow items to wrap if necessary */
}

.item {
  flex: 1;
  min-width: 240px;

  /* Allow items to grow and take up available space */
  .mat-mdc-form-field {
    display: flex;
  }
}

.influencer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.influencer-image {
  // border-radius: 50%;
  max-height: 40px;
  max-width: 40px;
  overflow: hidden;
  margin-right: 10px; // Add some space between the image and the name
  transition:
    max-height 0.3s ease-in-out,
    max-width 0.3s ease-in-out;
}

.influencer-name {
  display: flex;
  margin-left: 10px;
  width: 100%;
}

.influencer-container:hover .influencer-image {
  transition:
    max-height 0.3s ease-in-out,
    max-width 0.3s ease-in-out;
  max-height: 60px;
  max-width: 60px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.button-margin {
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.item:not(:last-child) {
  margin-right: 0.5rem;
}

.item:only-child {
  mat-form-field:not(.full-width) {
    width: 50%;
  }
}

.form-field-hint {
  font-size: 12px;
  margin-top: -12px;
  padding-bottom: 2px;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 16px;
  mat-icon {
    font-size: 20px !important;
    padding-top: 6px;
  }
}

.form-field-warning {
  font-size: 12px;
  padding-bottom: 2px;
  margin-top: -12px;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 16px;
  mat-icon {
    font-size: 20px !important;
    padding-top: 6px;
  }
}

.form-field-error {
  color: $error-red;
  font-size: 12px;
  padding-bottom: 2px;
  margin-top: -12px;
  padding-left: 16px;
  padding-right: 16px;
  line-height: 16px;
  mat-icon {
    font-size: 20px !important;
    padding-top: 6px;
  }
}

.error {
  color: $error-red;
}

.warning {
  color: $error-warning;
}

.success {
  color: $completed-green;
}

button.success.mat-mdc-raised-button:not(:disabled) {
  background-color: $completed-green !important;
  color: white;
  cursor: pointer;
}

.card {
  min-width: 120px;
  margin: 20px auto;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  flex: 1;
  margin-right: 20px;
}

.col:last-child {
  margin-right: 0;
}

// Material changes

div.mat-expansion-panel {
  background-color: white !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: white !important;
}

:root {
  @include mat.chips-overrides((
    outline-color: $dark-blue,
    disabled-outline-color: $light-grey-2,
    focus-state-layer-color: $dark-blue,
    elevated-selected-container-color: $light-blue,
    selected-label-text-color: $dark-blue,
  ));
}

div.mat-mdc-menu-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-mdc-menu-trigger.mdc-icon-button.mat-mdc-icon-button.mat-unthemed.mat-mdc-button-base {
  height: 24px !important;
}

.mat-mdc-menu-panel.mat-mdc-elevation-specific {
  background-color: #f6f6f6 !important;
}

:root {
  @include mat.radio-overrides(
    (
      disabled-label-color: $mid-grey,
      disabled-selected-icon-color: $mid-grey,
      disabled-unselected-icon-color: $mid-grey,
      label-text-color: $standard-text-colour,
    )
  );
}

div > div > div.mdc-radio__background > div.mdc-radio__outer-circle,
div > div > div.mdc-radio__background > div.mdc-radio__inner-circle,
div.mat-mdc-radio-button.mat-mdc-radio-checked.mat-ripple-element {
  border-color: $dark-blue !important;
}

.mat-mdc-menu-item {
  background-color: white !important;
}

.mat-hint {
  font-size: 12px;

  li {
    font-size: 12px;
  }
}

mat-icon {
  font-size: 26px;
  vertical-align: bottom;
  margin-bottom: 2px;
}

mat-icon[fontIcon="check"] {
  color: green;
}

mat-icon[fontIcon="close"] {
  color: $error-red;
}

.mat-mdc-form-field-hint-wrapper,
.mat-input-underline,
.mat-input-subscript-wrapper,
.mat-input-ripple {
  position: initial !important;
  display: block;
}

.mat-mdc-raised-button:not(:disabled) {
  background-color: $light-blue !important;
}

.pill {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 500;
  display: inline-block;
}

.draft {
  background-color: $pill-background-blue;
  color: $pill-foreground-grey;
}

.paused {
  background-color: $pill-background-yellow;
  color: $pill-foreground-grey;
}

.active {
  background-color: $pill-background-green;
  color: $pill-foreground-grey;
}

.completed {
  background-color: $pill-background-grey;
  color: $pill-foreground-grey;
}

.deleted {
  background-color: $pill-background-red;
  color: $pill-foreground-red;
}

.link {
  color: $mid-blue;
  text-decoration: underline;
  cursor: pointer;
}

.mat-hint:not(ul) {
  padding-left: 1rem;
}

mat-tab-group#eventTabs {
  > div.mat-mdc-tab-body-wrapper {
    > mat-tab-body.mat-mdc-tab-body.mat-mdc-tab-body-active {
      overflow-y: inherit !important;

      > div.mat-mdc-tab-body-content {
        overflow-y: inherit !important;
      }
    }
  }
}

.the-dense-zone {
  @include mat.button-density(-1);
}

// Material changes
mat-calendar {
  background-color: $light-grey;
}
mat-card-title.mat-mdc-card-title {
  margin-bottom: 1rem;
}

div.mat-mdc-select-panel {
  background-color: white !important;
}

.mat-drawer-inner-container {
  height: 100vh !important;
  overflow: auto !important;
  display: flex !important;
  flex-direction: column !important;
}

.sidenav-title {
  color: $dark-blue;
}

mat-sidenav.mat-drawer.mat-sidenav.sidenav.sidenav-container.mat-sidenav-fixed.mat-drawer-over.mat-drawer-opened,
mat-sidenav.mat-drawer.mat-sidenav.sidenav.sidenav-container.mat-drawer-side {
  background-color: $light-grey;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

mat-toolbar.mat-toolbar.mat-toolbar-single-row {
  background-color: transparent;
}

.mat-mdc-card {
  background-color: #ffff !important;
}

.mat-mdc-table {
  background-color: #ffff !important;
  border-color: var(
    --mat-table-row-item-outline-color,
    rgba(0, 0, 0, 0.12)
  ) !important;
  border-width: var(--mat-table-row-item-outline-width, 1px) !important;
  // border-style: solid !important;
  border-width: 1px !important;
  border-radius: 8px;
  overflow: auto !important;
}

.mdc-data-table__row:last-child.mat-mdc-cell,
.mat-mdc-cell mdc-data-table__cell:last-child {
  border-radius: 8px;
  border-bottom: inherit;
}

.mat-mdc-header-cell {
  background-color: $light-grey-2 !important;
}

h3.mat-mdc-list-item-title.mdc-list-item__primary-text.nav-title,
mat-nav-list
  > a
  > mat-icon.mat-icon.mat-mdc-list-item-icon.material-icons-outlined.material-icons.mat-ligature-font.mat-icon-no-color {
  color: $mid-grey;
}

.mat-mdc-nav-list .mat-mdc-list-item.mdc-list-item--activated {
  background-color: $light-blue !important;

  h3.mat-mdc-list-item-title.mdc-list-item__primary-text.nav-title,
  mat-nav-list
    > a
    > mat-icon.mat-icon.mat-mdc-list-item-icon.material-icons-outlined.material-icons.mat-ligature-font.mat-icon-no-color {
    color: $mid-blue;
    font-weight: bold;
  }
}

// mat-stepper>div.mat-horizontal-stepper-wrapper {
//     >div.mat-horizontal-stepper-header-container {
//         width: 60% !important;
//     }
// }

.sidenav-scroll-wrapper {
  background-color: lightgray; // $light-blue !important;
}

.mat-sidenav-content {
  margin-left: 0px;
  margin-right: 0px;
}

$--mdc-snackbar-container-color: $mid-blue;

.mat-mdc-dialog-surface{
  background-color: white !important;
  height: max-content !important;
}

.error-snackbar {
  --mdc-snackbar-container-color: #de3730;
  --mat-snack-bar-button-color: white;
}

.success-snackbar {
  > div.mat-mdc-snackbar-surface {
    background-color: $completed-green !important;
    color: $completed-green-2 !important;
  }
}

.warning-snackbar {
  --mdc-snackbar-container-color: #d47910;
  --mat-snack-bar-button-color: white;
}

.positive-disabled-slider {
  button.mdc-switch {
    width: 32px;
    cursor: default;
    > span.mdc-switch__track::after {
      background-color: var(--mdc-switch-selected-track-color);
    }
    > span.mdc-switch__track {
      opacity: 1;
    }
    > span.mdc-switch__handle-track > span.mdc-switch__handle {
      margin-left: 0px !important;
      margin-right: 0px;
      left: 4px;
      > span.mdc-switch__icons {
        opacity: 1;
      }
    }
  }
}

mat-stepper > div > div.mat-horizontal-content-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mat-step-icon {
  background-color: $light-blue !important;
  color: $dark-blue !important;
}

.mat-step-icon-selected {
  background-color: $dark-blue !important;
  color: $light-blue !important;
}

.mat-step-icon-state-done {
  background-color: $dark-blue !important;
  color: $light-blue !important;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
  background-color: inherit !important;
}

mat-expansion-panel.mat-expansion-panel {
  // background-color: #fcfcff;
  background-color: white;
  border-width: 1px !important;
  border-color: whitesmoke !important;
  border: solid;
}

mat-card {
  background-color: white;
  border-width: 1px !important;
  border-color: whitesmoke !important;
  border: solid;
}

// mat-mdc-form-field-bottom-align{
//   height: 0px !important;
// }

.mat-expansion-panel-header-title {
  font-size: larger;
}

.mat-expansion-panel-header-description {
  font-weight: normal;
  font-size: small;
}

.mat-mdc-paginator {
  background-color: transparent !important;
}

mat-divider {
  margin-bottom: 1rem !important;
}

// Amplify auth
button[amplify-button].amplify-button--primary {
  background-color: $dark-blue;
  border-radius: var(--mdc-filled-button-container-shape);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button[amplify-button].amplify-button--primary:hover {
  background-color: $dark-blue-hover;
}

div.amplify-tabs {
  button.amplify-tabs__item:not(.amplify-tabs__item--active) {
    font-weight: 500;
    color: $dark-blue;
  }

  button.amplify-tabs__item:not(.amplify-tabs__item--active):hover {
    font-weight: 500;
    color: $dark-blue-hover;
  }

  button.amplify-tabs__item.amplify-tabs__item--active {
    color: $dark-blue;
    border-color: $dark-blue;
  }

  button.amplify-tabs__item.amplify-tabs__item--active:hover {
    color: $dark-blue-hover;
  }
}

button.amplify-button--fullwidth.amplify-button--link.amplify-button--small {
  color: $dark-blue;
}

button.amplify-button--fullwidth.amplify-button--link.amplify-button--small:hover {
  background-color: $light-grey;
}

// Custom classes
.expansion-panel-group-heading {
  padding-left: 24px;
  h1, h2 {
    margin: 12px 0;
  }
}


.filter {
  height: 40px;
  font-size: 14px;
  border-radius: 5px;
  border-width: 0px;
  padding-left: 10px;
  border-color: in;
  outline: none;
  box-shadow: var(
    --mdc-elevated-card-container-elevation,
    var(--mat-app-level1)
  );
}
.filter-tall {
  height: 53px;
  font-size: 14px;
  border-radius: 5px;
  border-width: 0px;
  padding-left: 10px;
  border-color: in;
  outline: none;
  box-shadow: var(
    --mdc-elevated-card-container-elevation,
    var(--mat-app-level1)
  );
}
